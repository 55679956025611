<template>
  <div class="container-fluid p-0 h-100 d-flex flex-column">
    <div class="text-center pb-y step-header pb-5">
      <div class="h1">{{ current_step?.title }}</div>
      <div style="display: flex; width: 100%; justify-content: space-evenly;">
        <div>
          <b>{{ $t("NUMBER_OF_DEALERS") }}</b>
          {{ dealers.length }}
        </div>
        <div>
          <b>{{ $t("NUMBER_OF_DEALERS_W_TESTS") }}</b>
          {{ countDealersWithTest }}
        </div>
        <div>
          <b>{{ $t("NUMBER_OF_TESTS_OF_CHOSEN_DEALERS") }}</b>
          {{ countTests }}
        </div>
      </div>
    </div>

    <div class="table-responsive w-100 mb-3 overflow-hidden">
      <table class="table position-relative">
        <thead class="table-light">
          <tr style="border-color: #f8f9fa">
            <th scope="col">
              {{ $t("PARTNER_ID") }}
            </th>
            <th scope="col">
              {{ $t("PORSCHE_CENTER_NAME") }}
            </th>
            <th scope="col">
              {{ $t("CITY") }}
            </th>
            <th scope="col">
              <label>
                {{ $t("CONDUCT_TESTS") }}
              </label>
            </th>
            <th scope="col">
              <label>
                {{ $t("NUMBER_OF_TESTS") }}
                <i
                  class="bi bi-info-circle-fill"
                  id="info-tooltip"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Please, choose more than one test only if another scenario is not feasible in the dealership"
                ></i
              ></label>
            </th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th class="px-1">
              <input
                class="form-check-input checkbox text-end"
                type="checkbox"
                :indeterminate.prop="selectedTests('indeterminate')"
                :checked="selectedTests('checked')"
                @input="selectALl($event)"
                :disabled="configurationReadonly"
              />
            </th>
            <th class="text-end px-1">
              <div class="row">
                <div
                  class="col-lg-3 col-md-2 col-sm-1 form-check form-check-inline me-lg-1 me-md-1 me-sm-1"
                  id="number_of_test_container"
                ></div>
                <div
                  class="col-lg-3 col-md-2 col-sm-1 form-check form-check-inline me-0"
                >
                  <input
                    class="form-check-input checkbox"
                    type="checkbox"
                    :indeterminate.prop="numberOfTestsIs2('indeterminate')"
                    :checked="numberOfTestsIs2('checked')"
                    @input="numberOfTestAllDealer"
                    :disabled="configurationReadonly"
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody style="height: calc(100% - 68px); overflow-x: hidden;">
          <tr v-for="dealer in dealers" :key="dealer.id">
            <td>
              {{ dealer.partnerId }}
            </td>

            <td>
              {{ dealer.name }}
            </td>

            <td>
              {{ dealer.city }}
            </td>

            <td>
              <label>
                <input
                  class="form-check-input checkbox"
                  type="checkbox"
                  :id="'conduct-checkbox-' + dealer.id"
                  v-model="dealer.conductTests"
                  @input="conductTest($event, dealer)"
                  :disabled="configurationReadonly"
                />
              </label>
            </td>

            <td>
              <div class="row">
                <div
                  class="col-lg-3 col-md-2 col-sm-1 form-check form-check-inline"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="'inlineRadioOptions-' + dealer.id"
                    id="inlineRadio1"
                    value="1"
                    v-model="dealer.numberOfTests"
                    :disabled="!dealer.conductTests || configurationReadonly"
                    @input="changeNumberOfTests($event, dealer, 1)"
                  />
                  <label class="form-check-label" for="inlineRadio1">1</label>
                </div>

                <div
                  class="col-lg-3 col-md-2 col-sm-1 form-check form-check-inline me-0"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="'inlineRadioOptions-' + dealer.id"
                    id="inlineRadio2"
                    value="2"
                    v-model="dealer.numberOfTests"
                    :disabled="!dealer.conductTests || configurationReadonly"
                    @input="changeNumberOfTests($event, dealer, 2)"
                  />
                  <label class="form-check-label" for="inlineRadio2">2</label>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import * as bootstrap from "bootstrap";
import { useStore } from "vuex";

export default {
  name: "OnSiteNumberOfTests",
  computed: {
    current_step() {
      return this.$store.getters["sections/activeSection"];
    },
    dealers() {
      return this.$store.getters["dealers/getAllOnSiteDealersPerTests"];
    },
    configurationReadonly() {
      return this.$store.getters["configurations/currentConfigurationReadonly"];
    },
    countDealersWithTest() {
      var count = 0;
      this.dealers.forEach(dealer => {
        if(dealer.conductTests) count++;
      });
      return count;
    },
    countTests() {
      var count = 0;
      this.dealers.forEach(dealer => {
        count += Number(dealer.numberOfTests);
      });
      return count;
    }
  },
  async setup() {
    const store = useStore();
    await store.dispatch("dealers/getDealersPerTest", "on-site");
  },
  methods: {
    selectedTests(mode) {
      let selected = this.dealers.filter((dealer) => dealer.conductTests);

      if (selected.length === this.dealers.length) {
        return mode !== "indeterminate";
      } else if (selected.length > 0) {
        return mode === "indeterminate";
      } else {
        return false;
      }
    },
    numberOfTestsIs2(mode) {
      let numberOfTests = this.dealers.filter(
        (dealer) => dealer.numberOfTests === 2
      );

      if (numberOfTests.length === this.dealers.length) {
        return mode !== "indeterminate";
      } else if (numberOfTests.length > 0) {
        return mode === "indeterminate";
      } else {
        return false;
      }
    },
    conductTest(e, dealer) {
      dealer.conductTests = e.target.checked;
      dealer.numberOfTests = e.target.checked ? dealer.numberOfTests : 0;
      this.$store.dispatch("dealers/saveDealersPerTest", {
        mode: "on-site",
        dealer: dealer,
      });
    },
    changeNumberOfTests($event, dealer, numberOfTests) {
      dealer.conductTests = $event.target.checked;
      dealer.numberOfTests = numberOfTests;
      this.$store.dispatch("dealers/saveDealersPerTest", {
        mode: "on-site",
        dealer: dealer,
      });
    },

    selectALl(e) {
      this.$store.dispatch("dealers/conductAllDealerPerTest", {
        mode: "on-site",
        checked: e.target.checked,
      });
    },
    numberOfTestAllDealer(e) {
      this.$store.dispatch("dealers/numberOfTestAllDealer", {
        mode: "on-site",
        checked: e.target.checked,
      });
    },
  },
  mounted() {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );

    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  },
};
</script>
<style lang="scss" scoped>
thead tr:first-child th {
  padding-bottom: 0;
}
thead tr:last-child th {
  padding-top: 0;
}
@media (max-width: 768px) {
  thead #number_of_test_container {
    margin-left: 0.5em;
  }
}
</style>
