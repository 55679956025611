<template>
  <div
    class="table-responsive flex-full-height scrollable-table mb-3"
    id="question-table"
  >
    <table class="table">
      <questions-table-head
        :columns="[
          { title: '', key: '', class: 'table-col-s' },
          { title: $t('SELECT'), key: '', class: 'table-col-s' },
          {
            title: $t('TESTED_IN_PREVIOUS_WAVE'),
            key: '',
            class: 'table-col-m',
          },
          {
            title: $t('QUESTION_ID'),
            key: '',
            class: 'table-col-s',
          },
          {
            title: $t('QUESTION'),
            key: '',
            class: 'table-col-xl',
          },
          {
            title: $t('RESPONSE_OPTIONS'),
            key: '',
            class: 'table-col-l',
          },
          {
            title: $t('CATEGORY'),
            key: '',
            class: 'table-col-m',
          },
          {
            title: $t('EDIT'),
            key: '',
            class: 'table-col-s',
          },
        ]"
        :order="order"
        :orderDirection="order_direction"
        @orderChange="orderChange"
      >
      </questions-table-head>
      <tbody>
        <div v-for="question in questions" :key="question.guid">
          <tr v-if="question_id_modules.includes(question.id)">
            <th class="py-1 ps-4 bg-gray h4">
              {{ question.module }}
            </th>
          </tr>
          <tr
            :id="question.id"
            :class="
              (question?.checked ? 'selected-row ' : '') +
              (collapsed_question_id === question?.id ? 'collapsed-row ' : '') +
              (checkQuestionIsJ(question) ? 'text-muted' : '')
            "
          >
            <!-- Collapse toggle -->
            <td
              @click="
                collapseAllRowsIn(question?.id);
                editable_question_id = '';
              "
              class="cursor-pointer table-col-s"
            >
              <i
                class="bi bi-chevron-down"
                v-if="collapsed_question_id !== question?.id"
              ></i>
              <i class="bi bi-dash" v-else></i>
            </td>

            <!-- select checkboxes -->
            <td class="table-col-s">
              <label v-if="question">
                <input
                  class="form-check-input checkbox"
                  type="checkbox"
                  v-model="question.checked"
                  @input="selectQuestion($event, question)"
                  :disabled="
                    checkQuestionIsJ(question) ||
                    question.name.includes('_') ||
                    configurationReadonly
                  "
                />
              </label>
            </td>

            <!-- previous wave checkboxes -->
            <td class="table-col-m">
              <label v-if="question">
                <input
                  class="form-check-input checkbox"
                  type="checkbox"
                  :checked="
                    question.testedInPreviousWave || checkQuestionIsJ(question)
                  "
                  disabled
                />
              </label>
            </td>

            <!-- Question ID -->
            <td class="table-col-s">{{ question.name }}</td>

            <!-- Question Title & Possible choices -->
            <td class="collpased-content table-col-xl">
              <!-- Question Title -->
              <question-title
                :collapsed_question_id="collapsed_question_id"
                :question="question"
                :selected_languages="selected_languages"
                :editable_question_id="editable_question_id"
              />

              <!-- Question Possible choices -->
              <question-possible-choices
                :collapsed_question_id="collapsed_question_id"
                :question="question"
                :selected_languages="selected_languages"
                :editable_question_id="editable_question_id"
              />
            </td>

            <!-- Response options and Master briefing -->
            <td class="collpased-content table-col-l" v-if="question.type">
              <i
                class="bi bi-justify-left me-1"
                v-if="question.type.includes('text')"
              ></i>
              <img
                src="../assets/icons/sc.svg"
                alt="SC icon"
                class="img-fluid me-1"
                v-if="question.type.includes('SC')"
              />
              <img
                src="../assets/icons/mc.svg"
                alt="MC icon"
                class="img-fluid me-1"
                v-if="question.type.includes('MC')"
              />
              <i
                class="bi bi-calendar-date me-1"
                v-if="question.type.includes('date')"
              ></i>
              <i
                class="bi bi-clock me-1"
                v-if="question.type.includes('time')"
              ></i>
              {{ question.type }}
              <!--  Master briiefing  -->
              <question-description
                v-if="collapsed_question_id === question.id"
                :collapsed_question_id="collapsed_question_id"
                :question="question"
                :selected_languages="selected_languages"
                :editable_question_id="editable_question_id"
              />
            </td>

            <!-- Question category -->
            <td class="table-col-m">{{ question?.category }}</td>

            <!-- Edit question -->
            <td
              class="table-col-s"
              data-toggle="tooltip"
              data-placement="top"
              :title="$t('TRANSLATION_DISABLED_HOVER')"
            >
              <i
                class="bi bi-pencil-fill cursor-pointer text-muted"
                v-if="
                  checkQuestionIsV(question) &&
                  editable_question_id !== question?.id &&
                  selected_languages.length > 1
                "
                @click="disabledAction"
              ></i>
              <i
                class="bi bi-pencil-fill text-muted"
                style="opacity: 0.4"
                v-if="
                  checkQuestionIsV(question) &&
                  editable_question_id !== question?.id &&
                  selected_languages.length === 1
                "
              ></i>

              <!-- show when save mode -->
              <div
                class="h3"
                v-if="
                  checkQuestionIsV(question) &&
                  editable_question_id === question?.id
                "
              >
                <i
                  class="bi bi-check2 me-3 cursor-pointer"
                  @click="to_save_question = question"
                  data-bs-toggle="modal"
                  data-bs-target="#ConfirmQuestionEditModal"
                ></i>
                <i
                  class="bi bi-x cursor-pointer"
                  @click="dontSaveQuestion()"
                ></i>
              </div>
            </td>
          </tr>
        </div>
      </tbody>
    </table>
  </div>
  <confirm-question-edit-modal
    v-on:confirm="saveQuestion"
    v-on:beforeUnmount="to_save_question = {}"
  />
</template>

<script>
import QuestionsTableHead from "@/components/QuestionsTableHead";
import QuestionTitle from "@/components/questiontable/QuestionTitle";
import QuestionPossibleChoices from "@/components/questiontable/QuestionPossibleChoices";
import QuestionDescription from "@/components/questiontable/QuestionDescription";
import ConfirmQuestionEditModal from "@/components/modals/ConfirmQuestionEditModal";

export default {
  name: "QuestionsTable",
  components: {
    ConfirmQuestionEditModal,
    QuestionDescription,
    QuestionPossibleChoices,
    QuestionTitle,
    QuestionsTableHead,
  },
  props: ["filtered_questions", "selected_languages"],
  data() {
    return {
      collapsed_question_id: "",
      editable_question_id: "",
      order: "",
      order_direction: "",
      questions: [],
      default_language: {},
      question_id_modules: [],
      to_save_question: {},
    };
  },
  methods: {
    disabledAction() {
      console.warn("disabled action");
    },

    /**
     * Compare numbers or strings
     * @returns {number}
     */
    compare(a, b) {
      if (typeof a === "number" && typeof b === "number") {
        return a - b;
      }

      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    },

    setOrder(order, orderDirection) {
      this.order = order;
      this.order_direction = orderDirection;
      this.questions = this.questions.sort((q1, q2) => {
        const direction = orderDirection === "up" ? 1 : -1;
        return direction * this.compare(q1[order], q2[order]);
      });
    },

    orderChange(order) {
      // Get order direction
      let orderDirection = "up";
      if (order === this.order && this.order_direction === "up") {
        orderDirection = "down";
      }

      // Change order
      this.setOrder(order, orderDirection);
    },
    selectQuestion(e, question) {
      this.filtered_questions.forEach(async (q) => {
        if (question.id === q.id || q.name.includes(question.name + "_")) {
          if (e.target.checked) {
            await this.$store.dispatch("questions/checkQuestion", q);
          } else {
            await this.$store.dispatch("questions/uncheckQuestion", q);
          }
        }
      });
    },
    checkQuestionIsV(question) {
      return question?.category?.includes("V");
    },
    checkQuestionIsJ(question) {
      return question?.category?.includes("J");
    },
    collapseAllRowsIn(id) {
      if (this.collapsed_question_id === id) {
        this.collapsed_question_id = "";
      } else {
        this.collapsed_question_id = id;
      }
    },
    dontSaveQuestion() {
      this.editable_question_id = "";
      this.collapsed_question_id = "";
    },
    async saveQuestion() {
      let question = this.to_save_question;

      for (let translated_lang of question.translations) {
        if (translated_lang.isDefault) continue;

        translated_lang.title = document
          .getElementById(question.id)
          .querySelector(
            `input[data-editabledata="title"][data-lang="${translated_lang.languageCode}"][data-id="${translated_lang.id}"]`
          ).value;
      }

      // Save question Choices
      question.choices.forEach(function (choice) {
        for (let translated_choice_lang of choice.translations) {
          if (!translated_choice_lang.isDefault) {
            translated_choice_lang.title = document
              .getElementById(question.id)
              .querySelector(
                `input[data-editabledata="choices"][data-lang="${translated_choice_lang.languageCode}"][data-id="${translated_choice_lang.id}"]`
              ).value;
          }
        }
      });

      // Save question description
      for (let translated_lang of question.translations) {
        if (translated_lang.isDefault) continue;

        translated_lang.description = document
          .getElementById(question.id)
          .querySelector(`#description_${translated_lang.id}`).value;
      }

      this.$store.dispatch("questions/updateQuestionTranslations", question);
      this.editable_question_id = "";
    },
    editQuestion(question) {
      // hide all other open questions
      this.collapsed_question_id = question.id;
      this.editable_question_id = question.id;
    },
  },
  beforeMount() {
    this.default_language = this.languages.filter((l) => l.isDefault)[0];
    this.questions = this.filtered_questions;
  },
  watch: {
    selected_languages: {
      handler(languages) {
        return languages;
      },
      deep: true,
    },
    filtered_questions(questions) {
      let modules = new Set();
      this.questions = questions.map((q) => {
        if (q.category.includes("J")) {
          q.checked = true;
        }
        if (!modules.has(q.module)) {
          modules.add(q.module);
          this.question_id_modules.push(q.id);
        }
        return q;
      });
      if (this.order) {
        this.setOrder(this.order, this.order_direction);
      }
    },
    questions(questions) {
      let modules = new Set();
      questions.forEach((q) => {
        if (!modules.has(q.module)) {
          modules.add(q.module);
          this.question_id_modules.push(q.id);
        }
      });
    },
  },
  computed: {
    languages() {
      return this.$store.getters["getLanguages"];
    },
    configurationReadonly() {
      return this.$store.getters["configurations/currentConfigurationReadonly"];
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-gray {
  background-color: #cbcbcb;
  letter-spacing: 0.04rem;
}

table {
  thead {
    tr {
      border-left: none;
      border-right: none;
    }
  }

  tr {
    transition: all 0.15s linear;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 1px solid #dedede;

    &.selected-row {
      background-color: #eee;
    }

    &.collapsed-row {
      background-color: #dedede;
      border-left: 6px solid #333;
      padding-left: 0;
    }

    td {
      padding: 0.5rem;
    }
  }
}
</style>
