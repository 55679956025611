<template>
  <div
    class="modal fade"
    :id="modal_id"
    tabindex="-1"
    aria-labelledby="modalComponentLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-start">
          <p>{{ text }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="confirm"
            data-bs-dismiss="modal"
          >
            {{ button_text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmSubmitModal",
  props: ["title", "text", "button_text", "modal_id"],
  methods: {
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style scoped></style>
