<template>
  <i
    v-if="
      (column === order && direction === 'up') ||
      (column !== order && column === hoverTarget)
    "
    class="bi bi-arrow-up cursor-pointer"
  ></i>
  <i
    v-else-if="column === order && direction === 'down'"
    class="bi bi-arrow-down cursor-pointer"
  ></i>
  <i v-else class="bi bi-arrow-down cursor-pointer" style="opacity: 0"></i>
</template>
<script>
export default {
  name: "OrderSwitch",
  props: {
    column: { type: String, required: true },
    order: { type: String, required: true },
    direction: { type: String, required: true },
    hoverTarget: { type: String, required: false },
  },
};
</script>
