<template>
  <div
    class="container-fluid p-0 h-100 d-flex flex-column justify-content-start"
  >
    <div>
      <div class="text-center pb-y step-header pb-5">
        <div class="pb-5">
          <div class="h1 d-inline">
            {{ $t("OBJECTION") }}
          </div>
          <i class="bi bi-info-circle-fill ms-1 align-top custom-tooltip">
            <span class="tooltiptext">
              <span
                v-for="i in Array.from({ length: 12 }, (_, i) => i + 1)"
                :key="i"
                >{{ $t(`OBJECTION_TOOLTIP_${i}`) }}<br
              /></span>
            </span>
          </i>
        </div>

        <select
          v-model="selectedObjection"
          @change="addObjection"
          class="w-75 mb-5"
          :disabled="configurationReadonly"
        >
          <option value="-">Click to open the select list</option>
          <option
            v-for="option in objectionOptions"
            :key="option.objection"
            :value="option.objection"
          >
            {{ option.objection }}
          </option>
        </select>

        <textarea
          class="w-75 no-resize"
          rows="5"
          :placeholder="$t('OBJECTION_PLACEHOLDER')"
          v-model="objection"
          @change="updateObjection"
          :disabled="configurationReadonly"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, useStore } from "vuex";

export default {
  name: "ObjectionsSuggestions",
  computed: {
    ...mapGetters({
      current_step: "sections/activeSection",
      configurationReadonly: "configurations/currentConfigurationReadonly",
      currentSection: "onSiteSections/getActiveSection",
      nextSection: "onSiteSections/nextSection",
    }),
    objectionOptions() {
      return this.$store.getters["configurations/allObjectionsOptions"].filter(
        (v) => this.objection.indexOf(v.objection) === -1
      );
    },
  },
  setup() {
    const store = useStore();
    const config = store.state.configurations.currentConfiguration;
    const objection = config.objection ?? "";
    return { objection, selectedObjection: "-" };
  },
  methods: {
    addObjection(e) {
      if (!this.objection || this.objection === "") {
        this.objection = e.target.value;
      } else {
        this.objection += "\n" + e.target.value;
      }
      this.updateObjection();
      this.selectedObjection = "-";
      this.$forceUpdate();
    },
    updateObjection() {
      if(!this.objection) {
        var currentSection = this.currentSection;
        currentSection.checked = false;
        this.$store.dispatch("onSiteSections/updateSection", currentSection);

        var nextSection = this.nextSection;
        nextSection.checked = false;
        this.$store.dispatch("onSiteSections/updateSection", nextSection);
      }
      this.$store.dispatch("configurations/updateObjection", {
        objection: this.objection,
      });
    },
  },
};
</script>

<style scoped>
.no-resize {
  resize: none;
}
</style>
