<template>
  <thead class="table-light">
    <tr>
      <th
        v-for="column in activeColumns"
        :key="column.key + Math.random()"
        scope="col"
        class="sticky-top"
        :class="[{ 'cursor-pointer': column.key }, column.class]"
        @mousedown="$emit('orderChange', column.key)"
        @mouseenter="hoverTarget = column.key"
        @mouseleave="hoverTarget = ''"
      >
        {{ column.title }}
        <order-switch
          v-if="column.key.length"
          :column="column.key"
          :hover-target="hoverTarget"
          :order="order"
          :direction="orderDirection"
        ></order-switch>
      </th>
    </tr>
  </thead>
</template>
<script>
import OrderSwitch from "@/components/switches/OrderSwitch";

export default {
  name: "QuestionsTableHead",
  components: { OrderSwitch },
  emits: { orderChange: null },
  props: {
    columns: { type: Array, required: true, class: "" },
    order: { type: String, required: true },
    orderDirection: { type: String, required: true },
  },
  data() {
    return {
      hoverTarget: "",
    };
  },
  computed: {
    activeColumns() {
      return this.columns.filter((c) => !("condition" in c) || !!c.condition);
    },
  },
};
</script>
