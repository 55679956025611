<template>
  <div class="h-100 container-fluid d-flex flex-column p-0">
    <h3 class="text-center">{{ configurationDescription }}</h3>
    <Stepper />
    <div class="flex-full-height" v-if="onSite">
      <OnSiteWizard />
    </div>
    <div class="flex-full-height" v-else>
      <OffSiteWizard />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Stepper from "../components/Stepper.vue";
import OffSiteWizard from "./OffSiteWizard.vue";
import OnSiteWizard from "./OnSiteWizard.vue";

export default {
  name: "ConfigurationView",
  components: {
    Stepper,
    OnSiteWizard,
    OffSiteWizard,
  },
  async setup() {
    const store = useStore();
    const configuration =
      store.getters["configurations/getCurrentConfiguration"];
    return { onSite: configuration.onSite };
  },
  computed: {
    configurationDescription() {
      return this.$store.getters["configurations/getConfigurationDescription"];
    },
  },
};
</script>

<style lang="scss" scoped></style>
