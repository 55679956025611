<template>
  <div class="container-fluid p-0 h-100 d-flex flex-column scrollable-table">
    <div class="flex-full-height global-padding" v-if="currentStep?.name === 'cs'">
      <Suspense>
        <CoverStories />
      </Suspense>
    </div>

    <div
      class="flex-full-height global-padding"
      v-if="currentStep?.name === 'ch'"
    >
      <Suspense>
        <OffSiteNumberOfTests />
      </Suspense>
    </div>

    <div
      class="flex-full-height router-view-scrollable-content"
      v-if="currentStep.name === 'submit' || currentStep.name === 'approve'"
    >
      <OffSiteSubmit />
    </div>

    <div class="row no-gutters mt-3 mb-3 global-padding">
      <div class="col-6 d-flex justify-content-start">
        <button
          class="btn btn-outline-dark border-2 border-radius-0 me-3"
          @click="
            $router.push(
              `/projects/${$route.params.projectGuid}/waves/${$route.params.waveGuid}/configurations`
            )
          "
        >
          {{ $t("OVERVIEW") }}
        </button>
        <button
          class="btn btn-secondary"
          @click="prevSection"
          v-if="getPreviousStep"
        >
          {{ $t("PREVIOUS") }}
        </button>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <button
          class="btn btn-primary"
          @click="nextSection"
          :disabled="getStoryCount == 0"
          v-if="getNextSection"
        >
          {{ $t("NEXT") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
import OffSiteSubmit from "@/components/configurations/off-site/OffSiteSubmit";
const CoverStories = defineAsyncComponent(() => import("@/components/configurations/off-site/CoverStories"));
const OffSiteNumberOfTests = defineAsyncComponent(() =>
  import("@/components/configurations/off-site/OffSiteNumberOfTests")
);

export default {
  name: "OffsiteWizard",
  components: { OffSiteSubmit, CoverStories, OffSiteNumberOfTests },
  computed: {
    ...mapGetters({
      contactWays: "configurations/getOffSiteContactWays",
      current_config: "configurations/getCurrentConfiguration",
      currentStep: "offSiteSections/getActiveSection",
      getPreviousStep: "offSiteSections/prevSection",
      getNextSection: "offSiteSections/nextSection",
      getStoryCount: "stories/getStoryCount",
    }),
  },
  methods: {
    ...mapMutations({
      nextSection: "offSiteSections/setnextSection",
      prevSection: "offSiteSections/setprevSection",
    }),
  },
};
</script>
