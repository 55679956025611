<template>
  <div
    class="modal fade"
    tabindex="-1"
    id="ConfirmQuestionEditModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("CONFIRM_QUESTION_EDIT_MODAL_TITLE") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-start">
          <p>{{ $t("CONFIRM_QUESTION_EDIT_MODAL_TEXT") }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="confirm"
            data-bs-dismiss="modal"
          >
            {{ $t("CONFIRM_QUESTION_EDIT_MODAL_BTN") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    hide() {
      this.$emit("beforeUnmount");
    },
  },
  beforeUnmount() {
    this.hide();
  },
};
</script>

<style scoped></style>
