<template>
  <div class="container-fluid p-0 h-100 d-flex flex-column scrollable-table">
    <!--  show questions just when no submit   -->
    <div
      class="flex-full-height router-view-scrollable-content"
      v-if="currentStep.name === 'submit' || currentStep.name === 'approve'"
    >
      <ErrorBoundary>
        <OnSiteSubmit />
      </ErrorBoundary>
    </div>

    <div
      class="flex-full-height router-view-scrollable-content"
      v-else-if="currentStep.name === 'notpd'"
    >
      <ErrorBoundary>
        <OnSiteNumberOfTests />
      </ErrorBoundary>
    </div>
    <div
      class="flex-full-height router-view-scrollable-content"
      v-else-if="currentStep.name === 'os'"
    >
      <ErrorBoundary>
        <ObjectionsSuggestions />
      </ErrorBoundary>
    </div>

    <div class="d-flex flex-column flex-full-height global-padding" v-else>
      <div class="clearfix filter-row">
        <div class="float-start row-element number-of-question-element">
          {{ $t("QUESTIONS_SELECTED_COUNT") }}
          {{ selectedQuestions }} / {{ totalQuestions }}
        </div>
        <div class="form-check form-switch float-start step-filter row-element">
          <input
            v-if="currentStep"
            class="form-check-input"
            type="checkbox"
            id="only_voluntary"
            v-model="currentStep.filtered"
            @input="showOnlyMandatory"
          />
          <label class="form-check-label" for="only_voluntary">{{
            $t("ONLY_MANDATORY")
          }}</label>
        </div>
        <div class="float-end row-element">
          <button
            type="button"
            class="btn btn-outline-secondary dropdown-toggle text-truncate"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="max-width: 15rem"
          >
            <span v-for="language in languages" :key="language.id">
              <span
                v-for="(language_id, idx) in selected_languages"
                :key="language_id"
              >
                <span v-if="language.id === language_id"
                  >{{ language.value }}
                  <span v-if="idx !== 0">,</span>
                </span>
              </span>
            </span>
          </button>
          <ul
            class="dropdown-menu py-2"
            style="max-height: 18rem; overflow-y: auto"
          >
            <li v-for="language in languages" :key="language.id">
              <label
                :class="{
                  ' text-muted':
                    language.isDefault ||
                    !language.questionnaireLanguageAvailable,
                }"
              >
                <input
                  class="form-check-input checkbox mx-2"
                  type="checkbox"
                  :checked="selected_languages.includes(language.id)"
                  :disabled="
                    language.isDefault ||
                    !language.questionnaireLanguageAvailable
                  "
                  @input="
                    selected_languages.includes(language.id)
                      ? selected_languages.splice(
                          selected_languages.indexOf(language.id),
                          1
                        )
                      : selected_languages.push(language.id)
                  "
                  @change="initQuestions"
                />

                {{ language.value }}
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="scrollable-table"
        :class="{ 'flex-full-height': filtered_questions.length > 0 }"
      >
        <QuestionsTable
          :filtered_questions="filtered_questions"
          :selected_languages="selected_languages"
          :key="filtered_questions"
        />
        <p class="h5 text-center pt-5" v-if="filtered_questions.length === 0">
          {{ $t("NO_QUESTIONS_AVAILABLE") }}
        </p>
      </div>
    </div>
    <div class="row no-gutters mt-3 mb-3 global-padding">
      <div class="col-6 d-flex justify-content-start">
        <button
          class="btn btn-outline-dark border-2 border-radius-0 me-3"
          data-jp="overview-button"
          @click="
            $router.push(
              `/projects/${$route.params.projectGuid}/waves/${$route.params.waveGuid}/configurations`
            )
          "
        >
          {{ $t("OVERVIEW") }}
        </button>
        <button
          class="btn btn-secondary"
          @click="prevSection"
          v-if="getPreviousStep"
        >
          {{ $t("PREVIOUS") }}
        </button>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <button
          class="btn btn-primary"
          data-jp="next-button"
          :disabled="(currentStep?.id == -3 && !currentConfiguration.objection)"
          @click="nextSection"
          v-if="getNextSection"
        >
          {{ $t("NEXT") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorBoundary from "@/components/ErrorBoundary";

import QuestionsTable from "@/components/QuestionsTable";
import OnSiteSubmit from "@/components/configurations/on-site/OnSiteSubmit";
import OnSiteNumberOfTests from "@/components/configurations/on-site/OnSiteNumberOfTests";
import ObjectionsSuggestions from "@/components/configurations/on-site/ObjectionsSuggestions";

export default {
  name: "OnSiteWizard",
  components: {
    ErrorBoundary,
    OnSiteSubmit,
    QuestionsTable,
    OnSiteNumberOfTests,
    ObjectionsSuggestions,
  },
  data() {
    return {
      filtered_questions: [],
      questions: [],
      selectedQuestions: 0,
      totalQuestions: 0,
      selected_languages: [],
    };
  },

  computed: {
    shouldLoadQuestions() {
      if (this.currentStep?.id <= 0) return false;
      return true;
    },
    currentStep() {
      return this.$store.getters["onSiteSections/getActiveSection"];
    },
    currentConfiguration() {
      return this.$store.getters["configurations/getCurrentConfiguration"];
    },
    getPreviousStep() {
      return this.$store.getters["onSiteSections/prevSection"];
    },
    getNextSection() {
      return this.$store.getters["onSiteSections/nextSection"];
    },
    storeQuestions() {
      return this.$store.getters["questions/getAllQuestions"];
    },
    languages() {
      return this.$store.getters["getLanguages"].filter(
        (l) => l.questionnaireLanguageAvailable || l.fromMarket
      );
    },
    configurationReadonly() {
      return this.$store.getters["configurations/currentConfigurationReadonly"];
    },
  },

  beforeMount() {
    // a copy from all questions will be stored in this variable
    const defaultLang = this.languages.find((l) => l.isDefault);
    if (!defaultLang) throw new Error("no default Language");
    this.selected_languages.push(defaultLang.id);
    this.initQuestions();
  },

  methods: {
    nextSection() {
      this.$store.commit("onSiteSections/setnextSection");
    },
    prevSection() {
      this.$store.commit("onSiteSections/setprevSection");
    },
    initQuestions() {
      let activeSection = this.$store.getters[
        "onSiteSections/getActiveSection"
      ];
      if (this.shouldLoadQuestions) {
        this.$store
          .dispatch("questions/loadQuestions", {
            sectionId: activeSection.id,
            languages: this.selected_languages,
          })
          .then((result) => {
            this.questions = result;
            this.filtered_questions = [...this.questions];
            this.showOnlyMandatory(this.currentStep.filtered);
          });
      }
    },
    showOnlyMandatory(v) {
      let step = this.$store.getters["onSiteSections/activeSection"];
      if ((typeof v === "boolean" && v === true) || v?.srcElement?.checked) {
        step.filtered = true;
        this.filtered_questions = this.filtered_questions.filter((q) =>
          q.category.includes("J")
        );
      } else {
        this.filtered_questions = [...this.questions];
        step.filtered = false;
      }
      if (!this.configurationReadonly) {
        this.$store.dispatch("onSiteSections/updateSection", step);
      }
    },
  },

  watch: {
    currentStep() {
      this.initQuestions();
    },
    storeQuestions(questions) {
      this.selectedQuestions = questions.filter((q) => {
        return (
          q.checked &&
          // sub questions are not counted
          !q.name.includes("_") &&
          // J_ff or J_FF are not counted
          !q.category.toLowerCase().includes("_ff")
        );
      }).length;

      this.totalQuestions = questions.filter(
        (q) => !q.name.includes("_")
      ).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow-section {
  overflow: hidden auto;
}
.filter-row {
  margin-top: 15px;
  margin-bottom: 10px;
}
.row-element {
  margin-left: 20px;
  margin-right: 20px;
}
.number-of-question-element {
  width: 400px;
}
</style>
