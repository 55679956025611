<template>
  <div v-if="error">An error ocured: {{ error }}</div>
  <slot v-else></slot>
</template>

<script>
import { ref, onErrorCaptured, useSlots } from "vue";
export default {
  name: "ErrorBoundary",
  setup(prop, { slots }) {
    const error = ref(null);
    const slotName = slots[0]?.type?.name;
    onErrorCaptured((e) => {
      console.warn("ErrorBoundary caught an error in component:", slotName);
      console.error(e);
      error.value = e;
      return false;
    });
    return { error };
  },
};
</script>

<style></style>
