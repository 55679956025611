<template>
  <div v-if="collapsed_question_id === question.id" class="py-3 pe-2">
    <div v-for="lang in selected_languages" :key="lang" class="mb-2">
      <div class="mb-2">
        <span v-if="selected_languages.length > 1" class="text-muted small">
          {{ showLanguageName(languages.filter((l) => l.id === lang)[0]) }}
        </span>
        <span
          class="fw-bold"
          :class="{ 'ms-2': selected_languages.length > 1 }"
          >{{ $t("POSSIBLE_CHOICES") }}</span
        >
      </div>

      <!--  loop in all choices in this question  -->
      <div v-for="pc in question.choices" :key="pc">
        <div v-for="pct in pc.translations" :key="pct">
          <!-- show input when edit mode -->
          <div v-if="pct.languageId === lang">
            <div
              class="mb-1"
              v-if="
                question.category.includes('V') &&
                editable_question_id === question.id &&
                pc.translations.length > 1 &&
                !pct.isDefault
              "
            >
              <label>
                <input
                  type="text"
                  class="form-control"
                  :value="pct.title"
                  data-editabledata="choices"
                  :data-lang="pct.languageCode"
                  :data-id="pct.id"
                />
              </label>
            </div>
            <div v-else class="d-flex justify-content-between">
              <span>
                {{ pct.isDefault ? pc.title : pct.title }}
              </span>
              <span>
                ({{ pc.points || pc.points === 0 ? pc.points : "-" }})
              </span>
            </div>
          </div>
          <!-- show spans when no edit mode -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionPossibleChoices",
  props: [
    "collapsed_question_id",
    "editable_question_id",
    "question",
    "selected_languages",
  ],
  computed: {
    languages() {
      return this.$store.getters["getLanguages"];
    },
  },
  methods: {
    showLanguageName(lang_translated) {
      return lang_translated.countryCode === null
        ? lang_translated.languageCode
        : `${lang_translated.languageCode}-${lang_translated.countryCode}`;
    },
  },
};
</script>

<style scoped></style>
