<template>
  <div class="container-fluid mb-3">
    <div class="stepper-container" v-if="steps.length > 0">
      <div
        class="stepper-item"
        v-for="(step, index) of steps"
        v-bind:key="step.id"
        :class="{ 'stepper-item__checked': step.checked }"
      >
        <div
          class="stepper-item-divider"
          :class="{
            'end-item': firstItemId === step.id,
            checked: step.checked,
            'selected-gradient':
              activeSectionId === step.id &&
              firstItemId !== step.id &&
              prevSection?.checked,
          }"
        ></div>

        <div
          class="stepper-item-circle"
          :class="{
            checked: step.checked,
            selected: activeSectionId === step.id,
          }"
          @click="selectStep(step)"
        >
          <i class="stepper-item-icon bi-check2" v-if="step.checked"></i>
        </div>
        <div
          class="stepper-item-text"
          :class="{
            'checked-text': step.checked,
            'selected-text': activeSectionId === step.id,
          }"
          @click="selectStep(step)"
        >
          {{ step.title }}
        </div>

        <div
          class="stepper-item-divider"
          :class="{
            'end-item': lastItemId === step.id,
            checked:
              step.checked &&
              (steps[index + 1]?.checked ||
                steps[index + 1]?.id === activeSectionId),
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Stepper",
  methods: {
    selectStep(step) {
      if (step.checked) {
        let onSite = this.$store.getters[
          "configurations/getCurrentConfiguration"
        ].onSite;
        this.$store.commit(
          `${
            onSite ? "onSiteSections" : "offSiteSections"
          }/setActiveSectionPosition`,
          step.position
        );
      }
    },
  },
  computed: {
    steps() {
      let onSite = this.$store.getters["configurations/getCurrentConfiguration"]
        .onSite;
      return this.$store.getters[
        `${onSite ? "onSiteSections" : "offSiteSections"}/allSections`
      ];
    },
    activeSectionId() {
      let onSite = this.$store.getters["configurations/getCurrentConfiguration"]
        .onSite;
      return this.$store.getters[
        `${onSite ? "onSiteSections" : "offSiteSections"}/getActiveSection`
      ]
        ? this.$store.getters[
            `${onSite ? "onSiteSections" : "offSiteSections"}/getActiveSection`
          ].id
        : null;
    },
    lastItemId() {
      const steps = this.steps;
      return steps[steps.length - 1].id;
    },
    firstItemId() {
      const steps = this.steps;
      return steps[0].id;
    },
    prevSection() {
      let onSite = this.$store.getters["configurations/getCurrentConfiguration"]
        .onSite;
      return this.$store.getters[
        `${onSite ? "onSiteSections" : "offSiteSections"}/prevSection`
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.end-item {
  background-color: white !important;
}

.stepper-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  padding-top: 20px;

  .stepper-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    cursor: not-allowed;

    &:hover > div {
      text-decoration: underline;
    }
    &__checked {
      cursor: pointer;
    }

    &-text {
      position: absolute;
      bottom: -22px;
      text-align: center;
      width: 100%;
      color: $gray;
      font-size: 6px;

      // Ellipse overflow text
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }

    .checked {
      background-color: black;
      &-text {
        color: black;
      }
    }

    .selected-text {
      color: $primary;
    }

    .selected-gradient {
      background-image: linear-gradient(to right, black 0%, $primary 50%);
    }

    .selected {
      background-color: $primary;
    }

    &-circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $gray;

      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(md) {
        width: 40px;
        height: 40px;
      }

      .stepper-item-icon {
        color: white;
        font-size: 12px;
        line-height: 1px;

        @include media-breakpoint-up(md) {
          font-size: 25px;
        }
      }
    }

    &-divider {
      height: 3px;
      flex: 1;
      background-color: $gray;
    }
  }
}
</style>
