<template>
  <div
    v-for="lang_translated in question.translations"
    :key="lang_translated.toString()"
  >
    <!--  show input when edit clicked  -->
    <div
      v-if="
        question.category.includes('V') &&
        editable_question_id === question.id &&
        question.translations.length > 1 &&
        !lang_translated.isDefault
      "
      class="form-floating mb-1"
    >
      <input
        :value="lang_translated.title"
        type="text"
        class="form-control"
        data-editabledata="title"
        :data-lang="lang_translated.languageCode"
        :data-id="lang_translated.id"
        :id="lang_translated.id"
      />
      <label :for="lang_translated.id">
        {{ showLanguageName(lang_translated) }}
      </label>
    </div>

    <!--  show spans when not in edition mode  -->
    <div v-else>
      <span class="text-muted small" v-if="selected_languages.length > 1">{{
        showLanguageName(lang_translated)
      }}</span>
      <span
        v-if="lang_translated.isDefault"
        :class="{ 'ms-2': selected_languages.length > 1 }"
        >{{ question.title }}</span
      >
      <span v-else :class="{ 'ms-2': selected_languages.length > 1 }">{{
        lang_translated.title
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionTitle",
  props: [
    "collapsed_question_id",
    "editable_question_id",
    "question",
    "selected_languages",
  ],
  methods: {
    showLanguageName(lang_translated) {
      return lang_translated.countryCode === null
        ? lang_translated.languageCode
        : `${lang_translated.languageCode}-${lang_translated.countryCode}`;
    },
  },
};
</script>

<style scoped></style>
