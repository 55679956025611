<template>
  <div class="submit-container text-center" v-if="!loading">
    <div class="pb-1" v-if="isAdminUser">
      <h1>
        {{
          $t(
            currentConfigurationPending
              ? "APPROVE_CONTENT_TITLE"
              : "APPROVED_CONTENT_TITLE"
          )
        }}
      </h1>
      <p class="mb-0" v-show="currentConfigurationPending">
        {{ $t("APPROVE_CONTENT_TEXT_1") }} "{{ market.name }}"
        <br />
        {{ $t("APPROVE_CONTENT_TEXT_2") }} 
        "{{ getNewSurveyName() }}"
      </p>
    </div>
    <div class="pb-1" v-else>
      <h1>
        {{
          $t(
            currentConfigurationInProgress
              ? "SUBMIT_CONTENT_TITLE"
              : "SUBMITTED_CONTENT_TITLE"
          )
        }}
      </h1>
      <p v-show="currentConfigurationInProgress" class="mb-0">
        {{ $t("SUBMIT_CONTENT_TEXT") }}
      </p>
    </div>
    <div class="pb-1">
      <b>{{ $t("SUBMIT_CONTENT_COUNTING") }}</b
      ><br />
      <ul class="py-2">
        <li
          v-for="section in sections.filter(
            (s) => !!s.guid && s.name !== 'os' && s.name !== 'notpd'
          )"
          :key="section.id + Math.random()"
          :id="section.id"
          class="p-1"
        >
          {{ section.title }}: {{ sectionQuestions[section.id]?.checked }} /
          {{ sectionQuestions[section.id]?.total }}
        </li>
        <li>
          {{ $t("QUESTIONS_SELECTED_COUNT") }} {{ totalQuestions.checked }} /
          {{ totalQuestions.total }}
        </li>
      </ul>
    </div>
    <div class="pb-1 mx-auto export-btns">
      <p
        class="mb-3 fw-bold cursor-pointer btn btn-outline-primary"
        @click="exportToExcel"
      >
        <i class="bi bi-file-earmark-excel h4 me-2"></i>
        {{ $t("SUBMIT_CONTENT_EXPORT_EXCEL") }}
      </p>
    </div>

    <div class="mt-5 mb-5">
      <div class="text-center">
        <div class="h1 pb-3">{{ $t("OVERALL_COMMENT") }}</div>
        <p class="mb-0">
          {{ $t("OVERALL_COMMENT_SUBLINE_1") }}
        </p>
        <p class="mb-0 pb-3">
          {{ $t("OVERALL_COMMENT_SUBLINE_2") }}
        </p>
        <textarea
          class="w-75 no-resize"
          rows="5"
          :placeholder="$t('OVERALL_TEXTAREA_PLACEHOLDER')"
          v-model="overallComment"
          @change="updateOverallComment"
          :disabled="currentConfigurationReadonly"
        ></textarea>
      </div>
    </div>

    <div class="pt-1" v-if="isAdminUser" v-show="currentConfigurationPending">
      <div class="row">
        <div class="col-2" />
        <div class="col-3 custom-tooltip tooltip-middle-width">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#modalApprove"
            :disabled="!checkNumberOfTestsPerDealer"
          >
            {{ $t("TRANSFER_AND_CLOSE") }}
          </button>
          <span
            v-if="!checkNumberOfTestsPerDealer"
            class="tooltiptext"
            style="top: 100%"
            >{{ $t("NO_DELEARS_SELECTED") }}</span
          >
        </div>
        <div class="col-2" />
        <div class="col-3">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#modalReject"
          >
            {{ $t("REJECT_AND_CLOSE") }}
          </button>
        </div>
        <div class="col-2" />
      </div>
    </div>
    <div class="pt-1" v-else v-show="currentConfigurationInProgress">
      <p class="break-i18n">{{ $t("SUBMIT_TEXT") }}</p>
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        class="custom-tooltip tooltip-middle-width"
        :title="isAgencyUser ? $t('SUBMIT_CONTENT_DISABLED_LEADAGENCY') : null"
      >
        <button
          :disabled="isAgencyUser || !checkNumberOfTestsPerDealer || disabledBySalesExecutivesNamesAllowance"
          type="button"
          class="btn btn-primary"
          data-bs-target="#modalSubmit"
          data-bs-toggle="modal"
        >
          {{ $t("SUBMIT_CONTENT_CONFIG") }}
        </button>
        <span
          v-if="!checkNumberOfTestsPerDealer"
          class="tooltiptext"
          style="top: 100%"
          >{{ $t("NO_DELEARS_SELECTED") }}</span
        >
        <span
          v-else-if="disabledBySalesExecutivesNamesAllowance"
          class="tooltiptext"
          style="top: 100%"
          >{{ $t("CONFIRM_RECORDINGS")}}</span
        >
      </div>
    </div>

    <confirm-submit-modal
      :title="$t('SUBMIT_MODAL_TITLE')"
      :text="$t('SUBMIT_MODAL_TEXT')"
      :button_text="$t('SUBMIT_MODAL_BUTTON_TEXT')"
      :modal_id="'modalSubmit'"
      @confirm="submitConfig"
    />

    <confirm-submit-modal
      :title="$t('APPROVE_MODAL_TITLE')"
      :text="$t('APPROVE_MODAL_TEXT')"
      :button_text="$t('APPROVE')"
      :modal_id="'modalApprove'"
      @confirm="approveConfig"
    />

    <confirm-submit-modal
      :title="$t('REJECT_MODAL_TITLE')"
      :text="$t('REJECT_MODAL_TEXT')"
      :button_text="$t('REJECT')"
      :modal_id="'modalReject'"
      @confirm="rejectConfig"
    />
  </div>
</template>

<script>
import ConfirmSubmitModal from "@/components/modals/ConfirmSubmitModal";
import * as bootstrap from "bootstrap";
import { useStore } from "vuex";

export default {
  name: "OnSiteSubmit",
  components: {
    ConfirmSubmitModal,
  },
  data() {
    return {
      loading: true,
      sectionQuestions: {},
      totalQuestions: { checked: 0, total: 0 },
    };
  },
  setup() {
    const store = useStore();
    store.dispatch("dealers/getDealersPerTest", "on-site");
    let recordingSetup = store.getters["waves/getRecordingSetup"];
    const config = store.state.configurations.currentConfiguration;
    const overallComment = config.overallComment ?? "";
    return {
      recordingSetup, overallComment
    };
  },
  computed: {
    checkNumberOfTestsPerDealer() {
      return this.$store.getters["dealers/getAllOnSiteDealersPerTests"].some(
        (dealer) => dealer.conductTests
      );
    },
    contact_way() {
      return this.$store.getters["configurations/getCurrentContactway"];
    },
    scenario() {
      return this.$store.getters["configurations/getCurrentScenario"];
    },
    showExport() {
      return true; //process.env.NODE_ENV !== "production";
    },
    sections() {
      return this.$store.getters["onSiteSections/allSections"];
    },
    current_config() {
      return this.$store.getters["configurations/getCurrentConfiguration"];
    },
    market() {
      return this.$store.getters["users/getUserMarket"];
    },
    wave() {
      return this.$store.getters["waves/getActiveWave"];
    },
    isAdminUser() {
      return this.$store.getters["users/isAdminUser"];
    },
    isMarketUser() {
      return this.$store.getters["users/isMarketUser"];
    },
    isAgencyUser() {
      return this.$store.getters["users/isAgencyUser"];
    },
    currentConfigurationInProgress() {
      return this.$store.getters[
        "configurations/currentConfigurationInProgress"
      ];
    },
    currentConfigurationPending() {
      return this.$store.getters["configurations/currentConfigurationPending"];
    },
    currentConfigurationApproved() {
      return this.$store.getters["configurations/currentConfigurationApproved"];
    },
    currentConfigurationReadonly() {
      return this.$store.getters["configurations/currentConfigurationReadonly"];
    },
    isRecordingSetup() {
      return (
        this.recordingSetup.onsiteRecording === "audio" ||
        this.recordingSetup.onsiteRecording === "video" ||
        this.recordingSetup.offsiteRecording === "audio"
      );
    },
    disabledBySalesExecutivesNamesAllowance() {
      return (!this.recordingSetup.salesExecutivesNamesAllowance && this.recordingSetup.recordingLegal && this.isRecordingSetup)
    }
  },

  beforeMount() {
    // a copy from all questions will be stored in this variable
    this.initSections();
  },
  mounted() {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );

    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  },
  methods: {
    exportToExcel() {
      this.$store.dispatch("configurations/getExcelReport", true);
    },
    initSections() {
      // Reset
      this.sectionQuestions = {};
      this.totalQuestions = { checked: 0, total: 0 };
      const sections = this.$store.getters["onSiteSections/allSections"].filter(
        (s) => !!s.guid && s.name !== "os" && s.name !== "notpd"
      );
      for (const section of sections) {
        this.$store
          .dispatch("questions/loadQuestions", {
            sectionId: section.id,
            languages: "en",
          })
          .then((questions) => {
            this.sectionQuestions[section.id] = {
              checked: questions.filter(
                (q) => q.checked && !q.name.includes("_")
              ).length,
              total: questions.filter((q) => !q.name.includes("_")).length,
            };
            // Add to total count
            this.totalQuestions.checked += questions.filter(
              (q) => q.checked && !q.name.includes("_")
            ).length;
            this.totalQuestions.total += questions.filter(
              (q) => !q.name.includes("_")
            ).length;
          })
          .finally(() => (this.loading = false));
      }
    },
    async submitConfig() {
      await this.$store.dispatch(
        "configurations/submitConfig",
        this.current_config.id
      );
      await this.$router.push(
        `/projects/${this.$route.params.projectGuid}/waves/${this.$route.params.waveGuid}/configurations`
      );
    },
    async approveConfig() {
      await this.$store.dispatch(
        "configurations/approveConfig",
        this.current_config.id
      );
      await this.$router.push(
        `/projects/${this.$route.params.projectGuid}/waves/${this.$route.params.waveGuid}/configurations`
      );
    },
    async rejectConfig() {
      await this.$store.dispatch(
        "configurations/rejectConfig",
        this.current_config.id
      );
      await this.$router.push(
        `/projects/${this.$route.params.projectGuid}/waves/${this.$route.params.waveGuid}/configurations`
      );
    },
    updateOverallComment() {
      this.$store.dispatch("configurations/updateOverallComment", {
        overallComment: this.overallComment,
      });
    },
    getNewSurveyName() {
      var res = 
        this.wave.name.replace(/\s/g, "") + '_' +
        this.market.short + '_' +
        'On_' +
        this.scenario.title.replace(/\s/g, "") + '_' +
        this.contact_way.title.replace(/\s/g, "") + '_' +
        (this.current_config.testDrive ? "TD" : "noTD");
      return res;
    }
  },
  watch: {
    sections() {
      this.initSections();
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-container {
  flex: 1;
}

.export-btns {
  text-align: left;
  width: max-content;
}

ul {
  list-style-type: none;
}
</style>
